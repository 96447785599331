import React from 'react'
import { selectTheme, selectClearStyle, selectComponents } from '../utils/selectTheme';
import Select from 'react-select'

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }

]

const DynamicSelect = (props) => {


  return (
    <>
    <Select theme={selectTheme} styles={selectClearStyle} components={selectComponents} className="dynamicSelect" classNamePrefix="dynamicSelect" {...props} />
    </>
  )
}

export default DynamicSelect
