import React, { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";

import { Validator } from "../utils/validator";
import { BadResponseModalAtom } from "../modals/badResponseModal";

import { Modal } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiCornerUpLeft, FiInfo } from "react-icons/fi";

import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { StripeNoCardsAtom } from "../components/stripe";
import TermsModal, { ShowTermsModalAtom } from '../modals/termsModal';
import { forceUpdatePassword } from "../services/api";

export default function ForcePasswordUpdate() {
  const { auth, setAuth} = useAuth();

  const validator = new Validator();

	const stripeModal = useRecoilValue(StripeNoCardsAtom)

  const [badResponseModal, setBadResponseModal] = useRecoilState(BadResponseModalAtom);

  const [showModal, setShowModal] = useState(false);
  const [formSubmiting, setFormSubmiting] = useState(false);
  const [underFormMessage, setUnderFormMessage] = useState(null);
  const setShowTermsModal = useSetRecoilState(ShowTermsModalAtom);
  const [formValues, setFormValues] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
    termsAccepted: 0,
  });

  //close Modal
  const handleModalClose = () => {
    setShowModal(false);
  };
  // Terms modals
	const openTermsModal = (e) => {
    e.preventDefault()
		setShowTermsModal(true)
	}

  const changePassword = async (e) => {
    e.preventDefault();
    setFormSubmiting(true);
    const formData = new FormData(e.currentTarget);

    setFormSubmiting(true);

    formData.set("termsAccepted", formData.has("termsAccepted") ? "1" : "");
    let formValid = validator.isFormDataValid(formData, formValues.newPassword);

      if (formValid) {
        forceUpdatePassword(formData, auth.token)
        .then((response) =>{
          if (response.status == 204) {
            setFormSubmiting(false)
            setFormValues({
              password: '',
              newPassword: '',
              confirmPassword: '',
            })
            setAuth({
									...auth,
									user: {
										...auth.user,
										forcePasswordChange: false
									}
								})
            setUnderFormMessage('Password Successfully Changed.')
            return
          }
        })
          .catch((error) => {
            let data = error.response.data.error
            if (data.code) {
              switch (data.code) {
                case 'VALIDATION_ERROR':
                  validator.markInvalidFeilds(data.details)
                  // scrollTo(".is-invalid", -50)
                  break;
  
                case 'INTERNAL_ERROR':
                case 'SERVICE_UNAVAILABLE':
                default:
                  setBadResponseModal({
                    ...badResponseModal,
                    show: true,
                    error: {
                      title: data.code,
                      message: data.message
                    }
                  })
                  break;
              }
            }
            setFormSubmiting(false);
            return;
          });
      } else {
        //scrollTo(".is-invalid", -50)
        setFormSubmiting(false)
      }
  };

  const onInputChange = (e) => {
    let value = e.target.value;

    // pass password for comparing
    validator.classicInput(e.target, value, formValues.newPassword);

    // set value
    setFormValues({
      ...formValues,
      [e.target.name]: value,
    });
  };

  //check force Password update
  useEffect(() => {
    if (auth) {
      if (auth.user.forcePasswordChange) {
        setShowModal(true);
      }
    }
  }, [auth]);

  return (
    <>
      <Modal
        show={showModal && !stripeModal.show}
        size="md"
				restoreFocus={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
          {underFormMessage ? (
            ""
          ) : (
            <Modal.Header className="d-flex justify-content-center mt-2">
              <h4 className="text-primary">Set New Password</h4>
            </Modal.Header>
          )}
        <Modal.Body className="d-flex flex-column justify-content-center gap-4">
          <>
            {underFormMessage ? (
              <>
                <div className="mt-3 mb-1 text-center text-primary">
                  <h4>{underFormMessage}</h4>
                </div>
                <span className="text-center">
                  <button
                    onClick={handleModalClose}
                    className="btn btn-secondary text-light col-4">
                    Close
                  </button>
                </span>
              </>
            ) : (
              <form
                className="col-12"
                id="updatePasswordForm"
                method="post"
                onSubmit={changePassword}>
                {/* Old Password */}
                <div className="mb-3 form-input validate required">
                  <label htmlFor="password" className="form-label">
                    <span className="text-blue">*</span>Old Password:
                  </label>
                  <input
                    onChange={onInputChange}
                    value={formValues.password}
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                  />
                  <div className="feedback"></div>
                </div>

                <hr />

                {/* Password */}
                <div className="mb-3 form-input validate required field-password">
                  <label htmlFor="newPassword" className="form-label">
                    <span className="text-blue">*</span>New Password:
                    <OverlayTrigger
                      key="right"
                      placement="right"
                      overlay={
                        <Tooltip id="password-tooltip">
                          <small className="text-start d-block">
                            Password Requirements:
                            <br />- at least 8 characters
                            <br />- uppercase letters
                            <br />- lowercase letters
                            <br />- numbers
                          </small>
                        </Tooltip>
                      }>
                      <span>
                        <FiInfo className="ms-2" />
                      </span>
                    </OverlayTrigger>
                  </label>
                  <input
                    onChange={onInputChange}
                    value={formValues.newPassword}
                    type="password"
                    className="form-control"
                    id="newPassword"
                    name="newPassword"
                  />
                  <div className="feedback"></div>
                </div>

                {/* Confirm Password */}
                <div className="mb-3 form-input validate required field-confirm-password">
                  <label htmlFor="confirmPassword" className="form-label">
                    <span className="text-blue">*</span>Confirm New Password:
                  </label>
                  <input
                    onChange={onInputChange}
                    value={formValues.confirmPassword}
                    type="password"
                    className="form-control"
                    id="confirmPassword"
                    name="confirmPassword"
                  />
                  <div className="feedback"></div>
                </div>

                <div className="form-check form-input validate required field-terms mb-4">
                  <input
                    onChange={onInputChange}
                    className="form-check-input"
                    id="termsAccepted"
                    name="termsAccepted"
                    type="checkbox"
                  />
                  <label className="form-check-label" htmlFor="termsAccepted">
                    <span className="text-body">
                      By checking this box, you signify you have reviewed,
                      understand, meet and agree to our <span className="text-green link" onClick={(e) => openTermsModal(e)}>Terms & Conditions</span>.
                    </span>
                  </label>
                  <div className="feedback"></div>
                </div>

                {/* Submit */}
                <button
                  name="submit"
                  className="btn btn-primary w-100 text-white"
                  type="submit"
                  disabled={formSubmiting}>
                  {formSubmiting ? (
                    <>
                      Please wait...
                      <span
                        className="spinner-border spinner-border-sm ms-4"
                        role="status"
                        aria-hidden="true"></span>
                    </>
                  ) : (
                    <>Submit</>
                  )}
                </button>
              </form>
            )}
          </>
        </Modal.Body>
      </Modal>
      <TermsModal />
    </>
  );
}
