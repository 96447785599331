import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { atom, useRecoilState } from "recoil";

export const BadResponseModalAtom = atom({
	key: "BadResponseModalAtom",
	default: {
		show: false,
		error: {},
	},
});

function BadResponseModal() {
	const [badResponseModal, setBadResponseModal] = useRecoilState(BadResponseModalAtom);
	const [title, setTitle] = useState('');
	const [message, setMessage] = useState('');
	const [showErrorDetails, setShowErrorDetails] = useState(false)
	const [showErrorDetailsText, setShowErrorDetailsText] = useState(false)

	const setModalData = (modalTitle, modalMessage) => {
		switch (modalTitle) {
			case 'RESOURCE_NOT_FOUND':
				setTitle("Resource Not Found");
				setMessage(`We couldn't locate the resource you're looking for. It may not exist or has been removed. Please double-check the information you provided and try again. We apologize for any inconvenience.`)
				break
			case 'UNAUTHORIZED':
			case 'AUTH_TOKEN_EXPIRED':
				setShowErrorDetails(true);
				setTitle("Technical Difficulties: We're Working to Get Back on Track");
				setMessage("We apologize for the inconvenience, but we're currently experiencing technical difficulties. Our team is working diligently to resolve the issue and get everything back up and running smoothly. Thank you for your patience.")
				break;
			case 'ORDER_NOT_CREATED':
			case 'SHIP_TO_ADDRESS_NOT_FOUND':
			case 'RETURN_ADDRESS_ERROR':
			case 'INTERNAL_ERROR':
			case 'SERVICE_UNAVAILABLE':
				setShowErrorDetails(true);
				setTitle("Technical Difficulties: We're Working to Get Back on Track");
				setMessage("We apologize for the inconvenience, but we're currently experiencing technical difficulties. Our team is working diligently to resolve the issue and get everything back up and running smoothly. Thank you for your patience.")
				break;
			case 'FORBIDDEN':
				setTitle('Permission Denied');
				setMessage(`We're sorry, but it seems you do not have the required permissions to perform this action. Please contact your administrator or support team to request the necessary permissions or assistance. If you believe this is an error, kindly reach out to our support team for further assistance.`)
				break;
			case 'VALIDATION_ERROR':
				setTitle('Validation Error');
				setMessage('Please review your data and ensure the they properly entered.')
				break;
			default:
				setTitle(modalTitle);
				setMessage(modalMessage)
				break;
		}
	}

	// message define
	useEffect(() => {
		if (badResponseModal.error) {
			//badResponseModal.error.title ? setTitle(badResponseModal.error.title) : null
			setModalData(badResponseModal.error.title, badResponseModal.error.message)
			//badResponseModal.error.message ? setMessage(badResponseModal.error.message) : null
		} else {
			setTitle('')
			setMessage('')
		}

	}, [badResponseModal.error]);

	const handleClose = () => setBadResponseModal({ show: false, error: {} });

	var modal = (
		<>
			<Modal
				show={badResponseModal.show}
				onHide={() => setBadResponseModal({ show: false, error: {} })}
				// backdrop="static"
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				restoreFocus={false}
				scrollable={true}
				centered>
				<Modal.Header closeButton>{title}</Modal.Header>
				<Modal.Body>
					<ul className="list-group">{message}</ul>
					{showErrorDetails ?
						<div className="mt-4 row text-body-secondary">
							<div className="col-md-9">
								<span style={{ fontSize: '0.75rem' }}>
									<span className="fw-medium text-uppercase">Error code</span>: {badResponseModal.error.title}
								</span>
							</div>
							<div className="col-md-3 text-md-end">
								<button className={`btn btn-link text-body-secondary p-0 remove-underline-on-hover ${showErrorDetailsText ? 'd-none' : null}`}
									type="button" onClick={() => {setShowErrorDetailsText(true)}}>Show details</button>
							</div>
							<div className={`col-md-12 ${!showErrorDetailsText ? 'd-none' : null}`}>
								{badResponseModal.error.message}
							</div>
						</div>
						: null}
				</Modal.Body>
				<Modal.Footer>

					<Button onClick={handleClose} className="text-light btn-secondary btn-small">
						Close
					</Button>

				</Modal.Footer>
			</Modal>
		</>
	);

	return modal;
}

export default BadResponseModal;
