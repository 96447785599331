import useAuth from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import CCWrap from "./ccWrap";

import { atom, useRecoilState } from "recoil";
import { Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { checkCreditCard } from "../../services/api";

export const StripeNoCardsAtom = atom({
	key: "StripeNoCardsAtom",
	default: {
		show: false,
	},
});

export const StripeErrorAtom = atom({
	key: "StripeErrorAtom",
	default: {
		show: false,
		title: '',
		body: ''
	},
});


export default function Stripe() {
	let location = useLocation();
	const { auth, setAuth } = useAuth();
	const [modal, setModal] = useRecoilState(StripeNoCardsAtom);
	const [error, setError] = useRecoilState(StripeErrorAtom)
	const [blockModal, setBlockModal] = useState(false)

	useEffect(() => {
		if (auth) {
			if (!auth.user.creditCardNotNeeded) {
				if (!auth.user.isStripeOk) {

					checkCreditCard(auth.token)
						.then((response) => {
							if (response.data?.isCCValid) {
								setAuth({
									...auth,
									user: {
										...auth.user,
										isStripeOk: true
									}
								})
							} else {
								setModal({
									...modal,
									show: true,
								})
							}

						})
						.catch((error) => {
							let data = error.response?.data;
							if (data?.error?.code) {
								switch (data.error.code) {
									case 'FAILED_TO_RETRIEVE_CC':
										setError({
											...error,
											show: true,
											title: `Technical Difficulties: We're Working to Get Back on Track`,
											body: 'Failed to retrieve credit cards.'
										})
									case 'FAILED_TO_UPDATE_CUSTOMER':
										setError({
											...error,
											show: true,
											title: `Technical Difficulties: We're Working to Get Back on Track`,
											body: 'Failed to update customer.'
										})
										break;
									default:
										setError({
											...error,
											show: true,
											title: `Technical Difficulties: We're Working to Get Back on Track`,
											body: `<span className="fw-medium text-uppercase">Error code</span>: ${data.error.code}<br>${data.error.message}`
										})
										break;
								}
								setModal({
									...modal,
									show: true,
								})
							}
						})


				}
			}
		}
	}, [auth]);

	useEffect(() => {
		if (location.pathname == '/my-account/invitation') {
			setBlockModal(true)
		} else {
			setBlockModal(false)
		}
	}, [location.pathname])


	return (
		<>
			<Modal
				show={modal.show && !blockModal}
				size="lg"
				restoreFocus={false}
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header>
					<Modal.Title id="contained-modal-title-vcenter">
						{error.show ? error.title : 'Enter Credit Card Information'}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{auth ? error.show ?
						<div className="fs-5 mb-0">
							<p className="fs-6" dangerouslySetInnerHTML={{ __html: error.body }}></p>
						</div>
						:
						<div className="stripe">
							<CCWrap />
						</div>
						: null}
				</Modal.Body>
			</Modal>

		</>
	);
}

