import { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { FaPallet } from "react-icons/fa";
import { FiEdit, FiLayers, FiLogOut, FiMap, FiUsers, FiCreditCard  } from "react-icons/fi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { TbReportAnalytics } from "react-icons/tb";

import { Nav, Navbar, Dropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import ContosoAPi from '../components/contosoAPi';
import useAuth from "../hooks/useAuth";
import styles from "../styles/components/recyclingMenu.module.scss";

export default function Navigation() {

	const { auth } = useAuth();
	const { logOut } = useAuth();

	const [sessionState, setSessionState] = useState(false);
	const [sessionRoles, setSessionRoles] = useState("");
	const [sessionDummy, setSessionDummy] = useState("");
	const [showCardManagement, setShowCardManagement] = useState(false)
	const [isNav, setisNav] = useState(true)

	useEffect(() => {
		if (auth) {
			setSessionState(true)
			setSessionRoles(auth.user.roles)
			setSessionDummy(auth.user.dummyUser)
			setShowCardManagement(!auth.user.creditCardNotNeeded)
		} else {
			setSessionState(false)
			setSessionRoles(null)
			setSessionDummy(null)

		};
	}, [auth]);

	return (
		<>
			{sessionState ? (
				<Navbar collapseOnSelect expand="lg" className="navbar navbar-expand-lg bg-green navbar-dark">
					<Container>
						<div className="d-flex d-lg-none justify-content-end w-100 align-content-middle">
							<span className="d-md-flex d-lg-none">
								<ContosoAPi isNav={isNav} />
							</span>
							<Navbar.Toggle aria-controls="basic-navbar-nav" />
						</div>
						<Navbar.Collapse id="basic-navbar-nav" className="navbar-collapse">
							<Nav className="d-md-flex-column d-lg-flex justify-content-between w-100">
								<ul className="d-flex navbar-nav">

									{sessionRoles.some(role => role.code === 'createLabels')
										|| sessionRoles.some(role => role.code === 'orderBoxes') ?
										(<li className="nav-item">
											<a
												className={`nav-link d-flex align-items-center gap-1`}
												href="/recycling/order-supplies">
												<IconContext.Provider value={{ size: "1em" }}>
													<span className="d-lg-none d-flex">
														<FiLayers />
													</span>
													Order Supplies
												</IconContext.Provider>
											</a>
										</li>) : null}
									{sessionRoles.some(role => role.code === 'orderPallets') ?
										(<li className="nav-item">
											<a
												className={`nav-link d-flex align-items-center gap-1`}
												href="/recycling/pallet-pickup">
												<IconContext.Provider value={{ size: "1em" }}>
													<span className="d-lg-none d-flex">
														<FaPallet />
													</span>
													Pallet Pickup
												</IconContext.Provider>
											</a>
										</li>) : null}
									{sessionRoles.some(role => role.code === 'reporting') && !sessionDummy ?
										(<li className="nav-item">
											<a
												className={`nav-link d-flex align-items-center gap-1`}
												href="/recycling/reporting">
												<IconContext.Provider value={{ size: "1em" }}>
													<span className="d-lg-none d-flex">
														<TbReportAnalytics />
													</span>
													Reporting
												</IconContext.Provider>
											</a>
										</li>) : null}
									{!sessionDummy ?
										(<>
											<li className="nav-item">
												<a
													className={`nav-link d-flex align-items-center gap-1`}
													href="/recycling/resources">
													<IconContext.Provider value={{ size: "1em" }}>
														<span className="d-lg-none d-flex">
															<HiOutlineDocumentReport />
														</span>
														Resources
													</IconContext.Provider>
												</a>
											</li>
											<li className="nav-item">
												<a
													className={`nav-link d-flex align-items-center gap-1`}
													href="/recycling/locations">
													<IconContext.Provider value={{ size: "1em" }}>
														<span className="d-lg-none d-flex">
															<FiMap />
														</span>
														Locations
													</IconContext.Provider>
												</a>
											</li>
										</>
										) : null}
								</ul>
								<ul className="m-0 p-0">
									<li className="d-flex gap-1">
										<span className="d-lg-flex d-none">
											<ContosoAPi isNav={isNav} />
										</span>
										<ul className={`d-flex navbar-nav px-0 px-lg-2 py-0 ${styles.clUserLinks}`}>
											<Dropdown className="nav-item">
												<Dropdown.Toggle variant="transparent" id="dropdown-basic" className="nav-link">
													My Account
												</Dropdown.Toggle>
												<Dropdown.Menu>
													<Dropdown.Item className="d-flex gap-2 align-items-center lh-1 pb-1 my-1" href="/my-account"><FiEdit /> Edit My Account</Dropdown.Item>
													{sessionRoles.some(role => role.code === 'userManagement') ? <Dropdown.Item className="d-flex gap-2 align-items-center lh-1 pb-1 my-1" href="/user/user-management"><FiUsers /> User Management</Dropdown.Item> : null}
													{showCardManagement ? <Dropdown.Item className="d-flex gap-2 align-items-center lh-1 pb-1 my-1" href="/my-account/cards"><FiCreditCard /> Credit Card Management</Dropdown.Item> : null}
												</Dropdown.Menu>
											</Dropdown>
											<li className="nav-item">
												<button
													className="nav-link d-flex align-items-center gap-2 logout"
													onClick={() => logOut()}>
													Logout <FiLogOut />
												</button>
											</li>
										</ul>
									</li>
								</ul>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			) : (
				""
			)}
		</>
	);
}
