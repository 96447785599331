import React, { useEffect, useState } from 'react';
import useAuth from "../hooks/useAuth";


 const SUBSCRIPTION_KEY=process.env.REACT_APP_PUBLIC_OCP_APIM_SUBSCRIPTION_KEY;
 const CONTOSO_BASE_URL=process.env.REACT_APP_PUBLIC_CONTOSO_URL;



export default function ContosoAPi({isNav}) {  //fali mi props iz navigacije
  const { auth } = useAuth();

  const [returnBalace, setReturnBalance] = useState("");
  const [hideBalance, setHideBalance] = useState(false);
  const [hasVal, setHasVal] = useState(isNav);

  const getContoso = async (returnCustomerNo) => {
    try {
      const response = await fetch(`${CONTOSO_BASE_URL}/cloverapi-integrationlayer/NavisionCES/ReturnCustomer/${returnCustomerNo}/ReturnBalance`, {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache",
          "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY
        }
      })

      const data = await response.json();

      if (response.ok) {
        let roundedNumber = data.returnsBalance.toFixed(2);
        setReturnBalance(roundedNumber);
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if (auth.user && auth.user.CESAccount && auth.user.CESAccount.returnCustomerNo !== null && auth.user.CESAccount.returnCustomerNo !== undefined && !auth.user.locationUser && auth.user.roles.some((role) => role.code === "userManagement")) {
      const returnCustomerNo = auth.user.CESAccount.returnCustomerNo;
      getContoso(returnCustomerNo);
    } else {
      setHideBalance(true)
    }
  }, [])

  return (
    <>
      {hideBalance ? "" : (
        <>
          {hasVal ? (
            <>
              {returnBalace ? (
                <ul className={`d-flex p-2 m-0 text-light align-content-center`}>
                  <li className="fs-6 text-nowrap d-flex align-items-center gap-1">
                    Your Balance: ${returnBalace}
                  </li>
                </ul>) : ""}
            </>
          ) : (
            <div>
              {returnBalace ? (
                <div>
                  Your Balance: ${returnBalace}
                </div>) : ""}
            </div>
          )
          }
        </>
      )}
    </>
  )
}