import React from "react";
import { Helmet } from "react-helmet";
import { IconContext } from "react-icons";
import { FaPallet } from "react-icons/fa";
import { FiEdit, FiLayers, FiLogOut, FiMap, FiUsers } from "react-icons/fi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { TbReportAnalytics } from "react-icons/tb";
import ContosoAPi from '../../components/contosoAPi';
import useAuth from "../../hooks/useAuth";
import styles from "../../styles/pages/dashboard.module.scss";

export default function Dashboard() {
	const { auth } = useAuth();
	const { logOut } = useAuth();

	return (
		<>
		<Helmet>
				<title>Dashboard | Responsible Recycling</title>
			</Helmet>
          <div className="container">
            <div className="row align-items-start justify-content-end">
              <div className="clBox col-xxl-8 col-lg-8 col-md-10">
			<h3>
				Welcome, {auth.user.firstName} {auth.user.lastName}
			</h3>
			<div className="pb-3 fs-5">Responsible Recycling Dashboard</div>
			<div className="clGreenLine"></div>
			<div className="mt-5 fs-3 text-primary">
				<ContosoAPi />
			</div>
			<div className="mt-5">
				<ul
					className={`p-0 d-flex flex-wrap gap-2 justify-content-around ${styles.clLinks}`}>

					{auth.user.roles.some(role => role.code === 'createLabels')
						|| auth.user.roles.some(role => role.code === 'orderBoxes') ?
						(<li className="bg-light border border-warning">
							<a
								className="p-2 nav-link text-primary"
								href="/recycling/order-supplies">
								<IconContext.Provider value={{ size: "3em" }}>
									<span className="d-block text-center mb-2">
										<FiLayers />
									</span>
								</IconContext.Provider>
								<span className="d-block text-center">Order Supplies</span>
							</a>
						</li>) : null}

					{auth.user.roles.some(role => role.code === 'orderPallets') ?
						(<li className="bg-light border border-warning">
							<a
								className="p-2 nav-link text-primary"
								href="/recycling/pallet-pickup">
								<IconContext.Provider value={{ size: "3em" }}>
									<span className="d-block text-center mb-2">
										<FaPallet />
									</span>
								</IconContext.Provider>
								<span className="d-block text-center">Pallet Pickup</span>
							</a>
						</li>) : null}

					{auth.user.roles.some(role => role.code === 'reporting') && !auth.user.dummyUser ?
						(<li className="bg-light border border-warning">
							<a
								className="p-2 nav-link text-primary"
								href="/recycling/reporting">
								<IconContext.Provider value={{ size: "3em" }}>
									<span className="d-block text-center mb-2">
										<TbReportAnalytics />
									</span>
								</IconContext.Provider>
								<span className="d-block text-center">Reporting</span>
							</a>
						</li>) : null}

					{!auth.user.dummyUser ?
						(<>
							<li className="bg-light border border-warning">
								<a
									className="p-2 nav-link text-primary"
									href="/recycling/resources">
									<IconContext.Provider value={{ size: "3em" }}>
										<span className="d-block text-center mb-2">
											<HiOutlineDocumentReport />
										</span>
									</IconContext.Provider>
									<span className="d-block text-center">Resources</span>
								</a>
							</li>
							<li className="bg-light border border-warning">
								<a
									className="p-2 nav-link text-primary"
									href="/recycling/locations">
									<IconContext.Provider value={{ size: "3em" }}>
										<span className="d-block text-center mb-2">
											<FiMap />
										</span>
									</IconContext.Provider>
									<span className="d-block text-center">Locations</span>
								</a>
							</li>
						</>
						) : null}
				</ul>
				<ul
					className={`p-0 mt-5 d-flex flex-wrap gap-2 justify-content-around ${styles.clLinks}`}>

					{auth.user.roles.some(role => role.code === 'userManagement') ?
						(<li className="bg-light border border-warning">
							<a
								className="p-3 nav-link d-flex gap-1 align-items-center"
								href="/user/user-management">
								<span className="d-flex gap-1 align-items-center">
									<FiUsers /> User Management
								</span>
							</a>
						</li>) : null}


					<li className="bg-light border border-warning">
						<a
							className="p-3 nav-link d-flex gap-1 align-items-center"
							href="/my-account">
							<span className="d-flex gap-1 align-items-center">
								<FiEdit /> Edit My Account
							</span>
						</a>
					</li>
					<li className={`bg-danger border-danger ${styles.dangerHover}`}>
						<button className="p-3 nav-link d-flex gap-1 align-items-center w-100" onClick={() => logOut()}>
							<span className="d-flex gap-1 align-items-center text-light">
								<FiLogOut /> Logout
							</span>
						</button>
					</li>
				</ul>
			</div>
			</div>
			</div>
			</div>
		</>
	);
}
