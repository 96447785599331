export const selectTheme = (theme) => ({
	...theme,
	borderRadius: 0,
	control: (baseStyles, state) => ({
		...baseStyles,
		borderColor: state.isFocused ? "#a4ce9f" : "#a0a0a0",
	}),
	colors: {
		...theme.colors,
		primary: "#489c3f",
		primary25: "#489c3f40",
		primary50: "#489c3f80",
		primary75: "#489c3fbf",
	},
});

export const selectStyles = {
	control: (provided, state) => ({
		...provided,
		boxShadow: state.menuIsOpen ? '0 0 0 0.25rem rgba(72, 156, 63, 0.25)' : "none",
		borderColor: state.menuIsOpen ? "#a4ce9f" : "#a0a0a0", // Border color when menu is open
		"&:hover": {
			borderColor: state.menuIsOpen ? "#a4ce9f" : "#a0a0a0", // Adjust the hover color as needed
		},
		minHeight: "35px",
		backgroundColor: state.isDisabled ? '#fff' : provided.backgroundColor,
	}),
	option: (provided, state) => ({
		...provided,
		wordBreak: "break-word",
	}),
};

export const selectClearStyle = {
	control: (provided, state) => ({
		...provided,
		boxShadow: state.menuIsOpen ? '0 0 0 0.25rem rgba(72, 156, 63, 0.25)' : "none",
		borderColor: state.menuIsOpen ? "#a4ce9f" : "#a0a0a0", // Border color when menu is open
		"&:hover": {
			borderColor: state.menuIsOpen ? "#a4ce9f" : "#a0a0a0", // Adjust the hover color as needed
		},
		minHeight: "35px",
		backgroundColor: state.isDisabled ? '#fff' : provided.backgroundColor,
	}),
	option: (provided, state) => ({
		...provided,
		wordBreak: "break-word",
	}),
	singleValue: (provided, { selectProps: { menuIsOpen } }) => ({
		...provided,
		color: menuIsOpen ? '#33333357' : provided.color
	})
}

export const selectComponents = {
	// IndicatorSeparator: () => null
}

