import React from 'react'
import styles from "../styles/components/navbar.module.scss";

export default function Header() {

  return (
    <nav className="navbar navbar-expand-lg bg-white">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src="/images/logo@3.svg" />
        </a>
        <div className="d-flex">
          <ul className={`navbar-nav ms-auto ${styles.clNavbar}`}>
            <li className="nav-item">
              <a className={`${styles.navLink} nav-link`} href='/contact-us'>
                CONTACT US
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
