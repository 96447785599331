import React from 'react'
import styles from "../styles/components/footer.module.scss";

export default function Footer() {
  return (
    <div>
        <footer className={styles.footer}>
        <div className="container">
          <div className="pt-3 pb-2">
            <a className="" href="/">
              <img src="/images/logo@3.svg" className={styles.imageSmall} />
            </a>
          </div>
          <div className={`pt-3 pb-2 ${styles.cltopLine}`}>
            <div className="d-flex justify-content-between">
              <div>
                © 2024 Responsible Recycling
              </div>
              <div className="navbar navbar-expand p-0">
                <ul className="navbar-nav me-auto">
                  <li className="nav-item px-2">
                    <a className="" href="/privacy-policy">
                      Privacy Policy
                    </a>
                  </li>
                  <li className="nav-item px-2">
                    <a className="" href="/terms-and-conditions">
                      Terms And Conditions
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
