
import CCAdd from './ccAdd';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY);

export default function CCWrap() {
	return (
		<Elements stripe={stripePromise}>
			<CCAdd />
		</Elements>
	);
}