import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = () => {
	const { auth } = useAuth();
	const location = useLocation();
	const [canView, setCanView] = useState(canUserViewPage(location.pathname, auth))

	// useEffect(() => {
	// 	console.log(canUserViewPage(location.pathname, auth))
	// 	console.log(location.pathname)
	// 	setCanView(canUserViewPage(location.pathname, auth))
	// }, [location.pathname])

	return (
		auth?.user && canView
			? <Outlet /> : <Navigate to="/" state={{ from: location }} replace />
	)
}

export default RequireAuth;


function canUserViewPage(path, auth) {
	let userRoles = auth?.user.roles;

	switch (path) {
		case '/user/user-management':
			return userRoles && userRoles.some(role => role.code === 'userManagement');

		case '/recycling/order-supplies':
			return userRoles && (
				userRoles.some(role => role.code === 'createLabels')
				|| userRoles.some(role => role.code === 'orderBoxes')
			);

		case '/recycling/pallet-pickup':
			return userRoles && userRoles.some(role => role.code === 'orderPallets');

		case '/recycling/reporting':
			return userRoles && userRoles.some(role => role.code === 'reporting') && !auth.user.dummyUser;

		case path.match(/cards/)?.input:
		case path.match(/card/)?.input:
			let canViewCC = auth?.user.creditCardNotNeeded;
			return !canViewCC;

		case '/forgot-password':
			return !auth;

		case '/register':
			return !auth;

		case '/logout':
			return !auth;

		case '/recycling/resources':
		case '/recycling/locations':
			if (!auth) {
				return false
			}
			if (!auth.user) {
				return false
			}
			return !auth.user.dummyUser;

		default:
			return userRoles && true
	}
}