import React, { useState } from 'react'
import { useCookies } from "react-cookie";

import styles from "../styles/components/cookieBanner.module.scss";

export default function CookieBanner() {
	const [cookies, setCookie, removeCookie] = useCookies();
	const [hide, setHide] = useState(() => {
		const cookie_banner = cookies["acceptCookie"];
		return cookie_banner ? true : false
	});

	const setCookieBanner = () => {
		const currentDate = new Date();
		const expirationDate = new Date(currentDate.getTime() + 180 * 24 * 60 * 60 * 1000);
		setCookie('acceptCookie', 1 , { path: '/', expires: expirationDate })
		setHide(true)
	}

	if (hide) {
		return (<></>)
	} else {
		return (
			<div className={styles.cookieBanner}>
				<p className="cookieBannerText">
					Our website uses cookies to continuously improve your experience and display content geared to your interests. By using our website and services, you
					agree to our
					<a href="/privacy-policy"> use of cookies.</a>
				</p>
				<button className="btn btn-sm btn-primary text-white px-5" onClick={setCookieBanner}>
					Got It
				</button>
			</div>
		)
	}


}
