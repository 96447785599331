import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { Modal, Button } from "react-bootstrap";

export const SessionExpiredModalAtom = atom({
	key: "SessionExpiredModalAtom",
	default: false,
});

function SessionExpiredModal() {
	const [modal, setModal] = useRecoilState(SessionExpiredModalAtom);
	const handleClose = () => {
		setModal(false);
	}

	

	var element = (
		<>
			<Modal
				show={modal}
				onHide={() => handleClose()}
				size="md"
				restoreFocus={false}
				scrollable={true}
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header closeButton><Modal.Title>Information</Modal.Title></Modal.Header>
				<Modal.Body>
				Your session has expired, you are now logged out.
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={handleClose} className="text-light btn-secondary btn-small">
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);

	return element;
}

export default SessionExpiredModal;
