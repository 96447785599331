import CryptoJS from "crypto-js"

const secretPass = "FAd213aD2ejkldjad2e1e2lkdj219ufalfds8vhxlvc9a";

export const encrypt = (text) => {
	const encrypted = CryptoJS.AES.encrypt(
		JSON.stringify(text),
		secretPass
	).toString();

	return encrypted;
};

export const decrypt = (text) => {
	const bytes = CryptoJS.AES.decrypt(text, secretPass);
	const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	return decrypted;
};