import React, { useState, useEffect, useRef } from "react";
import { useRecoilState } from 'recoil';

import useAuth from "../hooks/useAuth";
import { BadResponseModalAtom } from "../modals/badResponseModal";
import { refreshEmailToken } from "../services/api";

import styles from "../styles/components/nonConfirmEmail.module.scss";

export default function NonConfirmEmail() {
	const { auth, setAuth } = useAuth();
	const [badResponseModal, setBadResponseModal] = useRecoilState(BadResponseModalAtom);

	const [confirmed, setConfirmed] = useState(true);
	const [emailResend, setEmailresend] = useState(false);

	const buttonRef = useRef(null);

	const resendEmailVerification = async () => {
		buttonRef.current.disabled = true;

		refreshEmailToken(auth.token)
			.then((response) => {
				setConfirmed(true);
				setEmailresend(true);
				setAuth({
					...auth,
					user: {
						...auth.user,
						emailResend: true,
					}
				})
			})
			.catch((error) => {
				let data = error.response.data;
				switch (data.error.code) {
					case 'INTERNAL_ERROR':
					case 'SERVICE_UNAVAILABLE':
					case 'TOKEN_EXPIRED':
						setBadResponseModal({
							...badResponseModal,
							show: true,
							error: {
								title: `Security Code Expired`,
								message: `Your security code has timed out and is no longer valid. For your security, we can't use expired codes. Please initiate the ‘Confirm Email’ process again to receive a new security code via email. If you continue to experience issues, please contact our support team for assistance.`
							}
						})
						break;
					case 'UNAUTHORIZED':
					default:
						setBadResponseModal({
							...badResponseModal,
							show: true,
							error: {
								title: data.error.code,
								message: data.error.message
							}
						})
						break;
				}
			})
	};

	useEffect(() => {
		if (auth) {
			setConfirmed(auth.user.emailConfirmed);
			auth.user.emailResend ? setEmailresend(true) : setEmailresend(false)
		}
	}, [auth]);

	return (
		<>
			{confirmed ? (
				""
			) : (
				<>
					{emailResend ? (
						<div className="bg-warning p-4 border border-danger text-center">
							<p className="m-0">
								Confirmation email sent! Please, check your email inbox.
							</p>
						</div>
					) : (
						<div className="bg-danger p-4 border border-warning text-light text-center">
							<p className="m-0">
								Email address is not confirmed. Please, confirm your email
								address. If you didn't receive an email, You can{" "}

								<button id="resend_btn" name="submit" className={`clPointer btn btn-primary text-link text-white ${styles.resend_btn}`} type="submit"
									ref={buttonRef}
									onClick={resendEmailVerification}
								>
									<strong>resend it</strong>.
								</button>
							</p>

						</div>
					)}
				</>
			)}
		</>
	);
}
