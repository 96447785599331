import "../../styles/components/stripe.module.scss";

import { useState, useEffect } from "react";

import useAuth from "../../hooks/useAuth";

import { Validator } from "../../utils/validator";
import TermsModal, { ShowTermsModalAtom } from "../../modals/termsModal";

import { useElements, useStripe, CardElement, AddressElement } from "@stripe/react-stripe-js";


import { useRecoilState, useSetRecoilState } from "recoil";
import { StripeNoCardsAtom, StripeErrorAtom } from ".";
import DynamicSelect from "../DynamicSelect";
import useCountries from "../../hooks/countries";

import { addCreditCard } from "../../services/api";

export default function CCAdd() {
	const { auth, setAuth, logOut } = useAuth();
	const validator = new Validator;
	const tempCountries = useCountries();

	const setShowTermsModal = useSetRecoilState(ShowTermsModalAtom)

	const [startCounter, setStartCounter] = useState(false)

	const stripe = useStripe();
	const elements = useElements();
	const [stripeModal, setStripeModal] = useRecoilState(StripeNoCardsAtom)
	const setError = useSetRecoilState(StripeErrorAtom)

	const [formSubmiting, setFormSubmiting] = useState(false)
	const [formDisabled, setFormDisabled] = useState(false)
	const [formValues, setFormValues] = useState({
		name: "",
		address: "",
		address2: "",
		city: "",
		country: "",
		state: "",
		zip: "",
	})

	const [formMessage, setFormMessage] = useState({
		valid: null,
		text: null
	})
	const [statesSelect, setStatesSelect] = useState({
		loading: true,
		isClearable: false,
		options: [],
		isSearchable: true,
		placeholder: "Loading, please wait...",
		value: "",
	});
	const [countrySelect, setCountrySelect] = useState({
		loading: true,
		isClearable: false,
		options: [],
		isSearchable: true,
		placeholder: "Loading, please wait...",
		value: "",
	});

	const options = {
		hidePostalCode: true,
		style: {
			base: {
				iconColor: '#489c3f',
				color: '#000',
				fontWeight: '500',
				fontFamily: '"Outfit", sans-serif',
				fontSize: '16px',
				fontSmoothing: 'antialiased',
				':-webkit-autofill': {
					color: '#000',
				},
				'::placeholder': {
					textTransform: 'capitalize',
					color: '#6c757d',
				},
			},
			invalid: {
				iconColor: '#dc3545',
				color: '#dc3545',
			},
		},
	}

	const [countries, setCountries] = useState([])
	const [countdown, setCountdown] = useState(10);

	useEffect(() => {
		setCountries(tempCountries)
	}, []);

	// Load states
	useEffect(() => {
		if (countries) {
			// promeni opcije za state dropdown
			let tempStateOptions = [];
			countries.map((item) =>
				tempStateOptions.push({
					value: item.abrv,
					label: item.name,
				})
			);

			setCountrySelect({
				...countrySelect,
				options: tempStateOptions,
				loading: false,
			});
			setStatesSelect({
				...statesSelect,
				loading: false,
				placeholder: "Select Country First",
			});
		}
	}, [countries]);


	useEffect(() => {
		if (!stripe || !elements) {
			// Stripe.js has not loaded yet, wait for it to load
			return;
		}

		const cardElement = elements.getElement(CardElement);

		if (!cardElement) {
			console.error('Card Element not found!');
			return;
		}

		const handleChange = (event) => {
			if (event.error) {
				setFormMessage({
					valid: false,
					text: event.error.message
				})
			} else {
				setFormMessage({
					valid: true,
					text: ''
				})
			}
		};

		// Add event listener
		cardElement.on('change', handleChange);

		// Make sure to clean up the event listener when the component is unmounted
		return () => {
			cardElement.off('change', handleChange);
		};
	}, [stripe, elements]);


	// Function to validate the credit card
	const formSubmit = async (e) => {
		e.preventDefault()
		setFormDisabled(true)

		if (!stripe || !elements || !auth) {
			return;
		}

		const formData = new FormData(document.querySelector('#stripeAddCardForm'))

		let formValid = validator.isFormDataValid(formData, '', false, '#stripeAddCardForm', formData.get("country"))
		const cardElement = elements.getElement(CardElement);

		const { token, error } = await stripe.createToken(cardElement, {
			name: formData.get("name").trim(),
			address_line1: formData.get("address").trim(),
			address_line2: formData.get("address2").trim(),
			address_city: formData.get("city").trim(),
			address_state: formData.get("state").trim(),
			address_zip: formData.get("zip").trim(),
			address_country: formData.get("country").trim(),
		});

		if (formValid && !error) {
			setFormSubmiting(true)

			addCreditCard(auth.token, token.id, true)
				.then(() => {
					setFormMessage({
						valid: true,
						text: ''
					})
					setFormSubmiting(false)
					setStartCounter(true)

				})
				.catch((error) => {
					setFormSubmiting(false)
					let data = error.response.data;
					switch (data.error.code) {
						case 'FAILED_TO_ADD_CC':
							setError({
								...error,
								show: true,
								title: `Technical Difficulties: We're Working to Get Back on Track`,
								body: 'Failed to update customer.'
							})
							break;
						case 'FAILED_TO_RETRIEVE_CUSTOMER':
							setError({
								...error,
								show: true,
								title: `Technical Difficulties: We're Working to Get Back on Track`,
								body: 'Failed to update customer.'
							})
							break;
						case 'MISSING_STRIPE_TOKEN':
							setError({
								...error,
								show: true,
								title: `Technical Difficulties: We're Working to Get Back on Track`,
								body: 'Failed to update customer.'
							})
							break;
						default:
							setError({
								...error,
								show: true,
								title: `Technical Difficulties: We're Working to Get Back on Track`,
								body: `<span className="fw-medium text-uppercase">Error code</span>: ${data.error.code}<br>${data.error.message}`
							})
							break;
					}
				})

		} else {
			if (error) {
				switch (error.code) {
					case "validation_error":
					case "incomplete_number":
						setFormMessage({
							valid: false,
							text: error.message
						})
						break;

					default:
						setFormMessage({
							valid: false,
							text: error.message
						})
						break;
				}
			}

			setFormSubmiting(false)
			setFormDisabled(false)

		}
	};

	// Input changed
	const onInputChange = (e) => {
		let value;
		// format value
		switch (e.target.name) {
			case "phoneNo":
				value = validator.formatPhoneNumber(e.target.value);
				break;
			case "email":
				value = e.target.value.trim();
				break;
			case "city":
				value = validator.formatCity(e.target.value);
				break;
			default:
				value = e.target.value;
				break;
		}

		// pass password for comparing
		validator.classicInput(e.target, value, '', countrySelect.value.value);

		// set value
		setFormValues({
			...formValues,
			[e.target.name]: value,
		});
	};

	// Select changed
	const onCountryChange = (event, e) => {
		setCountrySelect({
			...countrySelect,
			value: event,
		});
		setFormValues({
			...formValues,
			country: event.value,
		});

		let tempStateOptions = [];
		countries
			.find((item) => item.abrv == event.value)
			.states.map((item) =>
				tempStateOptions.push({
					value: item.abrv,
					label: item.name,
				})
			);

		setStatesSelect({
			...statesSelect,
			options: tempStateOptions,
			placeholder: "Please Select",
			value: "",
		});
		validator.clearDynamicSelectError(e.name);

		const zipCodeEl = document.querySelector('[name=zip]')
		if (zipCodeEl.value) {
			validator.classicInput(zipCodeEl, zipCodeEl.value, '', event.value)
		}
	};

	const onStateChange = (event, e) => {
		setStatesSelect({
			...statesSelect,
			value: event,
		});
		setFormValues({
			...formValues,
			states: event.value,
		});
		validator.clearDynamicSelectError(e.name);
	};

	useEffect(() => {
		if (startCounter) {
			const timer = setTimeout(() => {
				if (countdown > 0) {
					setCountdown(countdown - 1);
				} else {
					setAuth({
						...auth,
						user: {
							...auth.user,
							isStripeOk: true
						}
					})
					setStripeModal({
						...stripeModal,
						show: false,
					})
				}
			}, 1000);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [startCounter, countdown]);

	const openTermsModal = (e) => {
		e.preventDefault()
		setShowTermsModal(true)
	}



	return (
		<>
			<form id="stripeAddCardForm" onSubmit={formSubmit}>
				<p>To use the Responsible Recycling Program, you must provide a credit card, which may be charged on a monthly basis to offset program costs. Charges will vary depending on the contents of your return shipments. </p>
				<p><span className="btn-link" onClick={(e) => openTermsModal(e)}>View complete program terms and conditions</span>.</p>

				<div className="row">
					<div className="col-lg-6">
						<CardElement className={'bg-white py-3 px-3 border ' + (!formMessage.valid && formMessage.text ? 'border-danger' : null)} options={options} />

						<div className="feedback invalid-feedback d-block mb-3" style={{ minHeight: '1rem' }}>{formMessage.text}</div>

					</div>
				</div>

				<div className="row">
					<div className="col-lg-6">
						{/* Name */}
						<div className="mb-3 form-input validate required">
							<label htmlFor="name" className="form-label">
								<span className="text-blue">*</span>Cardholder Name:
							</label>
							<input onChange={onInputChange} value={formValues.name} type="text" className="form-control" id="name" name="name" maxLength="50" />
							<div className="feedback"></div>
						</div>
					</div>

					<div className="col-lg-6">
						{/* Address */}
						<div className="mb-3 form-input validate required">
							<label htmlFor="address" className="form-label">
								<span className="text-blue">*</span>Address:
							</label>
							<input onChange={onInputChange} value={formValues.address} type="text" className="form-control" id="address" name="address" maxLength="35" />
							<div className="feedback"></div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-6">
						{/* Address 2 */}
						<div className="mb-3 form-input">
							<label htmlFor="address2" className="form-label">
								Address 2:
							</label>
							<input onChange={onInputChange} value={formValues.address2} type="text" className="form-control" id="address2" name="address2" maxLength="35" />
						</div>
					</div>
					<div className="col-lg-6">
						{/* City */}
						<div className="mb-3 form-input validate required field-city">
							<label htmlFor="city" className="form-label">
								<span className="text-blue">*</span>City:
							</label>
							<input onChange={onInputChange} value={formValues.city} type="text" className="form-control" id="city" name="city" maxLength="30" />
							<div className="feedback"></div>
						</div>
					</div>
				</div>

				<div className="row mb-2">
					<div className="col-lg-4">
						{/* Country */}
						<div className="mb-3 form-input validate select required">
							<label htmlFor="country" className="form-label">
								<span className="text-blue">*</span>Country:
							</label>
							<DynamicSelect id="country" name="country" isClearable={countrySelect.isClearable} options={countrySelect.options} isSearchable={countrySelect.isSearchable} placeholder={countrySelect.loading ? "Loading, please wait..." : "Please Select"} isLoading={countrySelect.loading} value={countrySelect.value} onChange={(event, e) => (event ? onCountryChange(event, e) : "")} />
							<div className="feedback"></div>
						</div>
					</div>
					<div className="col-lg-4">
						{/* State */}
						<div className="mb-3 form-input validate select required">
							<label htmlFor="state" className="form-label">
								<span className="text-blue">*</span>State:
							</label>
							<DynamicSelect id="state" name="state" isClearable={statesSelect.isClearable} options={statesSelect.options} isSearchable={statesSelect.isSearchable} placeholder={statesSelect.placeholder} isLoading={statesSelect.loading} value={statesSelect.value} onChange={(event, e) => (event ? onStateChange(event, e) : "")} />
							<div className="feedback"></div>
						</div>
					</div>
					<div className="col-lg-4">
						{/* Zip */}
						<div className="mb-3 form-input validate required field-zip">
							<label htmlFor="zip" className="form-label">
								<span className="text-blue">*</span>Zip:
							</label>
							<input onChange={onInputChange} value={formValues.zip} type="text" className="form-control" id="zip" name="zip" maxLength="10" />
							<div className="feedback"></div>
						</div>
					</div>
				</div>

				{startCounter ?
					<p className="fs-5 text-primary text-center">Card has been added to your account.<br />Popup will close in {countdown} seconds.</p>
					:
					(
						<>
							<div className="d-flex w-100 justify-content-between">
								<button name="button" className="btn btn-danger text-white" type="button" disabled={formDisabled} onClick={() => logOut()}>
									Logout
								</button>

								<button name="submit" className="btn btn-secondary text-white" type="submit" disabled={formDisabled}>
									{formSubmiting ? (
										<>
											Please wait...
											<span
												className="spinner-border spinner-border-sm ms-4"
												role="status"
												aria-hidden="true"></span>
										</>
									) : (
										<>Add Card</>
									)}

								</button>
							</div>
						</>
					)
				}




			</form>
			<TermsModal />
		</>
	);
}
