import { createContext, useState, useEffect, startTransition } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { encrypt, decrypt } from "./crypto";
import SessionAboutToExpireModal, { SessionAboutToExpireModalAtom } from "../components/modal/sessionAboutToExpireModal";

import { useRecoilState } from "recoil";
import { SessionExpiredModalAtom } from "../components/modal/sessionExpiredModal";
import { getUser } from "./api";

function getTimePlusAnHour() {
	const currentDate = new Date();
	const expirationDate = new Date(currentDate.getTime() + 60 * 60 * 1000);

	return expirationDate
}

const pagesForRefreshUser = [
	'/recycling/order-supplies',
	'/recycling/pallet-pickup',
	'/recycling/reporting',
	'/recycling/locations',
	'/user/user-management'
]

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
	let location = useLocation();
	const navigate = useNavigate();
	const [cookies, setCookie, removeCookie] = useCookies();
	const [auth, setAuth] = useState(() => {
		const user_cookie = cookies["cigrr_user"];
		return user_cookie ? decrypt(cookies["cigrr_user"]) : null
	});
	const [refreshingEvent, setRefreshingEvent] = useState(false)

	// session expiration modal
	const [showSessionModal, setShowSessionModal] = useRecoilState(SessionAboutToExpireModalAtom)
	const [sessionModalTime, setSessionModalTime] = useState(0)
	const [showExpiredModal, setShowExpiredModal] = useRecoilState(SessionExpiredModalAtom)

	useEffect(() => {
		const interval = setInterval(() => {
			if (localStorage.getItem('cigrr_expiration') && !showExpiredModal) {
				const currentDate = new Date();
				const expirationDate = new Date(localStorage.getItem('cigrr_expiration'));

				const timeDifferenceInMilliseconds = expirationDate - currentDate;
				const timeDifferenceInSeconds = Math.floor(timeDifferenceInMilliseconds / 1000);
				if (timeDifferenceInSeconds > 0 && timeDifferenceInSeconds < 300) {
					setSessionModalTime(timeDifferenceInSeconds)
					setShowSessionModal(true)
				} else if (timeDifferenceInSeconds <= 0) {
					sessionExpire()
				} else {
					setShowSessionModal(false)
				}
			} else {
				setShowSessionModal(false)
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [])

	useEffect(() => {
		if (auth) {
			sessionRefresh(auth)
		}
	}, [auth]);

	useEffect(() => {
		// refresh on path change
		if (auth && cookies["cigrr_user"]) {
			sessionRefresh(auth)
		}
		if (pagesForRefreshUser.includes(location.pathname) && auth) {
			getUser(auth.token)
			.then((response) => {
				let user = response.data.user;
				setAuth({
					...auth,
					user: user
				})
			})
			.catch((error) => {
				console.log(error)
			});
		}
	}, [location.pathname])

	useEffect(() => {
		// cookie expires -> clear auth context
		if (!cookies["cigrr_user"]) {
			removeAuthAndOther()
		}
	}, [cookies["cigrr_user"]])

	useEffect(() => {
		/* refresh on event only if there is auth object 
			 and session about to expire modal is not visible */
		function handleRefresh() {
			if (!refreshingEvent && auth && !showSessionModal && !showExpiredModal) {
				setRefreshingEvent(true)
				sessionRefresh(auth)
				setTimeout(()=> {
					setRefreshingEvent(false)
				}, 5000)
			}
		}

		window.addEventListener('keydown', handleRefresh);
		window.addEventListener('mousedown', handleRefresh);
		window.addEventListener('mousewheel', handleRefresh);
		window.addEventListener('DOMMouseScroll', handleRefresh);
		window.addEventListener('MSPointerDown', handleRefresh);
		window.addEventListener('MSPointerMove', handleRefresh);

		return () => {
			window.removeEventListener('keydown', handleRefresh);
			window.removeEventListener('mousedown', handleRefresh);
			window.removeEventListener('mousewheel', handleRefresh);
			window.removeEventListener('DOMMouseScroll', handleRefresh);
			window.removeEventListener('MSPointerDown', handleRefresh);
			window.removeEventListener('MSPointerMove', handleRefresh);
		}
	}, [auth, refreshingEvent, showSessionModal, showExpiredModal]);

	const removeAuthAndOther = () => {
		removeCookie('cigrr_user')
		localStorage.removeItem('cigrr_expiration')
		setAuth(null)
	}

	const logOut = () => {
		startTransition(() => {
			// set storage so user can view logout page
			localStorage.setItem('cigrr_can_see_logout', 'true')
			// navigate to page
			navigate('/logout')
			// hide modal
			setShowSessionModal(false)
			// remove auth
			removeAuthAndOther()
		});
	}

	const sessionExpire = () => {
		startTransition(() => {
			// hide modal with buttons and display expired session modal
			setShowSessionModal(false)
			setShowExpiredModal(true)
			// navigate
			navigate('/')
			// remove auth
			removeAuthAndOther()
		});
	}

	const sessionRefresh = (auth) => {
		let expiration_time = getTimePlusAnHour()
		setCookie('cigrr_user', encrypt(auth), { path: '/', expires: expiration_time })
		localStorage.setItem('cigrr_expiration', expiration_time)
		setShowSessionModal(false)
	}


	return (
		<AuthContext.Provider value={{ auth, setAuth, logOut }}>
			{children}
			<SessionAboutToExpireModal show={showSessionModal} time={sessionModalTime} logOut={logOut} sessionRefresh={sessionRefresh} auth={auth} />
		</AuthContext.Provider>
	);
};

export default AuthContext;
