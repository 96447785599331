import { Helmet } from "react-helmet";
import Forgot from "../components/forgot/Forgot"

export default function ForgotPage() {
	return (
		<>
			<Helmet>
				<title>Reset your Password | Responsible Recycling</title>
			</Helmet>
			<main className="clMainBg">
				<div className="container">
					<div className="row align-items-start justify-content-end">
						<div className="clBox col-lg-6">
							<Forgot />
						</div>
					</div>
				</div>
			</main>
		</>
	);
}