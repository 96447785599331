import { useState } from "react";
import { Validator } from "../../utils/validator";
import { forgotPassword } from "../../services/api";
import { IconContext } from "react-icons";
import { FiCornerUpLeft } from "react-icons/fi";


export default function ForgotForm() {
  const validator = new Validator();
  const [formValues, setFormValues] = useState({
    email: "",
  });
  const [error, setError] = useState();

  const [displayMessage, setDisplayMessage] = useState(false);
  const [showMessageSuccess, setShowMessageSuccess] = useState(false);
  const [showMessageError, setShowMessageError] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const onInputChange = (e) => {
    let value = e.target.value.trim();
    validator.classicInput(e.target, value);

    setFormValues({
      ...formValues,
      [e.target.name]: value,
    });
  };

  const submitEmail = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const email = formValues.email;

    let formValid = validator.isFormDataValid(formData);
    if (formValid) {
      setSpinner(true);
      setShowMessageError(false);

      forgotPassword(email)
        .then((response) => {
          // console.log('response: ', response);

          if (response.status === 204) {
            setSpinner(false);
            setDisplayMessage(true);
            setShowMessageSuccess(true);
            return;
          }
          if (response.ok) {
            const data = response.json();
            // console.log("ok");
          } else {
            const errorData = response.json();
            setSpinner(false);
            setDisplayMessage(false);
            setShowMessageSuccess(false);
            setShowMessageError(true);
          }
        })
        .catch((error) => {
          // console.log(error)
          setError(
            `<h4 className="m-0">Oops!</h4><div>The email or password you entered is incorrect. Please try again or reset your password if you’ve forgotten it.</div>`
          );
          setSpinner(false);
          setShowMessageError(true);
        });
    }
  };

  return (
    <>
      {displayMessage ? (
        <>
          {showMessageSuccess ? (
            <div className="text-light text-left text-green d-flex flex-column justify-content-center align-content-center p-1 mb-1">
              <h4 className="mb-3">Successfully Submitted!</h4>
              <div className="text-dark">
                <p>We'll send password reset instructions to your email if it's associated with a registered account.</p>
                <p>Please check your spam or junk folder if you don't see it in your inbox.</p>
              </div>
              <a className="btn btn-primary mt-3 text-light" href="/">
                <IconContext.Provider value={{ size: "1.5em" }}>
                  <FiCornerUpLeft /> Back to Login Page
                </IconContext.Provider>
              </a>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          <form onSubmit={submitEmail}>
            <div className="mb-3 form-input validate required field-email">
              <label htmlFor="email" className="form-label">
                <span className="text-blue">*</span>
                Enter your account email to reset the password:
              </label>
              <input
                type="text"
                onChange={onInputChange}
                value={formValues.email}
                id="email"
                name="email"
                placeholder="____@___.__"
                maxLength="80"
                className="form-control"
              />
              <div className="feedback"></div>
            </div>
            <div className="mb-3">
              <p>
                If this email is registered, we will send password reset instructions.
              </p>
            </div>
            <div className="mb-3">
              <button type="submit" disabled={spinner ? "disabled" : ""} className="btn btn-green w-100 text-white">
                {spinner ? (
                  <>
                    Please wait...
                    <span
                      className="spinner-border spinner-border-sm ms-4"
                      role="status"
                      aria-hidden="true"></span>
                  </>
                ) : (
                  <>Submit</>
                )}
              </button>
            </div>
          </form>
          {showMessageError ? (
            <div className="p-3 alert alert-danger text-center d-flex justify-content-center align-content-center my-3">
              <strong>Email Not Found!</strong>
            </div>
          ) : (
            ""
          )}
          <a href="/" className="d-flex justify-content-center">
            <i className="bi bi-arrow-left-short"></i> Go Back
          </a>
        </>
      )}
    </>
  );
}
