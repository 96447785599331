import { Modal, Button } from "react-bootstrap";
import { atom, useRecoilState } from "recoil";

export const SessionAboutToExpireModalAtom = atom({
	key: "SessionAboutToExpireModalAtom",
	default: false,
});


function SessionAboutToExpireModal({ show, time, logOut, sessionRefresh, auth }) {
	const [showModal, setShowModal] = useRecoilState(SessionAboutToExpireModalAtom)

	var element = (
		<>
			<Modal
				show={showModal}
				// onHide={() => handleClose()}
				size="sm"
				restoreFocus={false}
				scrollable={true}
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header><Modal.Title>Information</Modal.Title></Modal.Header>
				<Modal.Body>
					{`Your session will expire in ${Math.floor(time/60) + 1} minute${Math.floor(time/60) + 1 > 1 ? 's' : ''}.`}
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex w-100 justify-content-between">
						<Button onClick={logOut} className="text-light btn-danger btn-small">
							Log me out
						</Button>
						<Button onClick={() => {sessionRefresh(auth)}} className="text-light btn-secondary btn-small">
							Keep me logged in
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);

	return element;
}

export default SessionAboutToExpireModal;
