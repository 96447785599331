import React, { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { Modal, Button } from "react-bootstrap";
import Terms from "../pages/terms/termsPage";
import { scrollTo } from "../utils/scrollTo";
import { useNavigate } from "react-router-dom";


export const ShowTermsModalAtom = atom({
	key: "ShowTermsModalAtom",
	default: false,
});

function TermsModal() {
	const [modal, setModal] = useRecoilState(ShowTermsModalAtom);
	const navigate = useNavigate();

	useEffect(() => {
		const recFees = window.location.hash.includes('#rec_fees');
		if (recFees && modal) {
			document.querySelector('#rec_fees').scrollIntoView({ behavior: "smooth" })
		}
	}, [modal]);


	const handleClose = () => {
		// navigate(""); // Use navigate function to update the URL
		history.replaceState(null, '', window.location.pathname);
		setModal(false);
	}

	var element = (
		<>
			<Modal
				show={modal}
				onHide={() => handleClose()}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				restoreFocus={false}
				scrollable={true}
				id="termsModal"
				centered>
				<Modal.Header closeButton><Modal.Title>Terms and Conditions</Modal.Title></Modal.Header>
				<Modal.Body>
					<Terms />
				</Modal.Body>
				<Modal.Footer>

					<Button onClick={handleClose} className="text-light btn-secondary btn-small">
						Close
					</Button>

				</Modal.Footer>
			</Modal>
		</>
	);

	return element;
}

export default TermsModal;
