import React from 'react';
import ReactDOM from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';
import { RecoilRoot } from 'recoil';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './services/AuthProvider';
import App from './App';

import './styles/globals/globals.scss';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <RecoilRoot>
            <BrowserRouter>
                <AuthProvider>
                    <div className='main_app'>
                        <App />
                    </div>
                </AuthProvider>
            </BrowserRouter>
        </RecoilRoot>
    </CookiesProvider>

);
