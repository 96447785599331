import axios from "axios";

export const client = axios.create({
	baseURL: process.env.REACT_APP_PUBLIC_API_BASE_URL,
	responseType: "json",
	headers: {
		"Content-Type": "application/json",
		"X-Requested-With": "XMLHttpRequest",
	},
});


export const loginUser = (email, password) => {
	try {
		const response = client.post(
			'/login',
			{
				email: email,
				password: password
			}
		)
		return (response);
	} catch (error) {
		return (error);
	}
}

export const forgotPassword = (email) => {
	try {
		const response = client.post(
			'/forgot-password',
			{
				email: email,
			}
		)
		return (response);
	} catch (error) {
		return (error);
	}
}

export const registerForm = (formData) => {
	try {
		const response = client.post(
			'/register',
			{
				companyName: formData.get("companyName"),
				firstName: formData.get("firstName"),
				lastName: formData.get("lastName"),
				email: formData.get("email"),
				address: formData.get("address"),
				address2: formData.get("address2"),
				city: formData.get("city"),
				country: formData.get("country"),
				state: formData.get("state"),
				zip: formData.get("zip"),
				phoneNo: formData.get("phoneNo"),
				password: formData.get("password"),
				termsAccepted: parseInt(formData.get("termsAccepted")),
			}
		)
		return (response);
	} catch (error) {
		return (error);
	}
}

export const confirmEmail = (hash) => {
	try {
		const response = client.patch(
			'/confirm-email',
			{
				token: hash
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}

export const refreshEmailToken = (bearerToken) => {
	try {
		const response = client.put(
			'/refresh-email-token',
			{},
			{
				headers: {
					Authorization: `Bearer ${bearerToken}`,
				},
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}

export const setupNewPassword = (hash, password) => {
	try {
		const response = client.patch(
			'/reset-password/' + hash,
			{
				newPassword: password
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}

export const getUser = (token) => {
	try {
		const response = client.get(
			'/get-logged-in-user',
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
		return (response);
	} catch (error) {
		return (error);
	}
}

export const updateUser = (formData, token) => {
	try {
		const response = client.put(
			'/edit-my-account',
			{
				companyName: formData.get('companyName'),
				firstName: formData.get('firstName'),
				lastName: formData.get('lastName'),
				email: formData.get('email'),
				address: formData.get('address'),
				address2: formData.get('address2'),
				city: formData.get('city'),
				country: formData.get('country'),
				state: formData.get('state'),
				zip: formData.get('zip'),
				phoneNo: formData.get('phoneNo'),
			},
			{
				headers: {
					"Authorization": `Bearer ${token}`,
				},
			}
		);

		return response;
	} catch (error) {
		throw error; // Rethrow the error so it can be caught by the caller
	}
};

//ostavljati komentare za koji app je poziv
export const updatePassword = (formData, token) => {
	try {
		const response = client.patch(
			'/change-password',
			{
				password: formData.get('password'),
				newPassword: formData.get('newPassword')
			},
			{
				headers: {
					"Authorization": `Bearer ${token}`,
				},
			}
		);
		return response;
	} catch (error) {
		throw error; // Rethrow the error so it can be caught by the caller
	}
}

//force password update
export const forceUpdatePassword = (formData, token) => {
	try {
		const response = client.patch(
			'/change-password',
			{
				password: formData.get('password'),
				newPassword: formData.get('newPassword'),
				termsAccepted: parseInt(formData.get("termsAccepted"))
			},
			{
				headers: {
					"Authorization": `Bearer ${token}`,
				},
			}
		);
		return response;
	} catch (error) {
		throw error; // Rethrow the error so it can be caught by the caller
	}
}

export const getUsers = (token, getUserInit, userPerPage) => {
	try {
		const response = client.get(
			'/users',
			{
				headers: {
					"Authorization": `Bearer ${token}`,
				},
				params: {
					page: getUserInit.pageNo,
					perPage: userPerPage,
					filters: {
						search: getUserInit.search,
						order: {
							field: getUserInit.field,
							direction: getUserInit.direction,
						},
					}
				},
			}
		)
		return response;
	}
	catch (error) {
		throw error; // Rethrow the error so it can be caught by the caller
	}
}

export const deleteUser = (token, userID) => {
	try {
		const response = client.delete(
			`/users/${userID}`,
			{
				headers: {
					"Authorization": `Bearer ${token}`,
				},
			}
		)
		return response;
	}
	catch (error) {
		throw error; // Rethrow the error so it can be caught by the caller
	}
}

export const addUser = (formData, token, userId) => {
	try {
		const headers = {
			'Authorization': `Bearer ${token}`,
		};
		const userData = {
			companyName: formData.get('companyName'),
			firstName: formData.get('firstName'),
			lastName: formData.get('lastName'),
			email: formData.get('email'),
			address: formData.get('address'),
			address2: formData.get('address2'),
			city: formData.get('city'),
			country: formData.get('country'),
			state: formData.get('state'),
			zip: formData.get('zip'),
			phoneNo: formData.get('phoneNo'),
			roles: formData.get('roles').split(","),
		};
		let response;

		if (userId == 0) {
			response = client.post('/users', userData, { headers });
		} else {
			response = client.put(`/users/${userId}`, userData, { headers });
		}
		return response;
	}
	catch (error) {
		throw error; // Rethrow the error so it can be caught by the caller
	}
}

export const getEditUser = (token, userId) =>{
	try{
		const response = client.get(
			`/users/${userId}`,
			{
				headers: {
					"Authorization": `Bearer ${token}`,
				},
			}
		)
		return response;
	}
	catch (error) {
		throw error; // Rethrow the error so it can be caught by the caller
	}
}

export const getResources = (token, page, perPage) =>{
	try{
		const response = client.get(
			'/resources',
			{
				headers: {
					"Authorization": `Bearer ${token}`,
				},
				params: {
					page: page ? page : 1,
					perPage: perPage ? perPage : 10,
				}
			}
		)
		return response;
	}
	catch (error) {
		throw error; // Rethrow the error so it can be caught by the caller
	}
}


export const acceptWarningLockTerms = (token) => {
	try {
		const response = client.patch(
			'/accept-warning-lock-terms',
			{
				"t&cAccepted": true
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}

export const generateOrderSuppliesForm = (controller, token) => {
	try {
		const response = client.get(
			'/generate-order-supplies-form',

			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
				signal: controller.signal
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}

export const generatePalletPickupForm = (controller, token) => {
	try {
		const response = client.get(
			'/generate-pallet-pickup-form',

			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
				signal: controller.signal
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}

export const getLocations = (controller, token, getMaxLabels=false) => {
	try {
		const response = client.get(
			'/locations',
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					getMaxLabels: getMaxLabels
				},
				signal: controller.signal
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}


export const sendRecyclingCenterConfirmationEmail = (token, requestID, emails) => {
	try {
		const response = client.post(
			'/send-recycling-center-confirmation-email',
			{
				"requestID": requestID,
				"sendToEmails": emails
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}

export const submitOrderSuppliesForm = (token, body) => {
	try {
		const response = client.post(
			'/submit-order-supplies-form',
			body,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}

export const submitPalletPickupForm = (token, body) => {
	try {
		const response = client.post(
			'/submit-pallet-pickup-form',
			body,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}

//register -> get all resurces funtion

export const getReporting = (token, filters) =>{
	try {
		const response = client.post(
			'/generate-report',
			{
				environmentalImpactReport: filters.environmentalImpactReport,
				excel: filters.excel,
				fromDate: filters.fromDate,
				toDate: filters.toDate,
				hidePrice: filters.hidePrice
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}

// get all addresses for loactions page
export const getAllLocations = (token, filters) =>{
	try {
		const response = client.get(
			'/locations',
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					filters: {
						search: filters.search,
						order: {
							field: filters.field,
							direction: filters.direction,
						}
					},
					getMaxLabels: false,
					getCountries: true,
				}
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}

// get Location by ship to code on AddNewLocation Page
export const getLocation = (token, shipToCode) => {
	try {
		const response = client.get(
			`/locations/${shipToCode}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				}
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}

//submit location form on AddNewLocation.js

export const submitLocationForm = (token, shipToCode, formData) => {
	if (shipToCode == 0) {
        try {
			const response = client.post(
				'/locations',
				{
					companyName: formData.get("companyName"),
					address: formData.get("address"),
					address2: formData.get("address2"),
					city: formData.get("city"),
					countryAbrv: formData.get("country"),
					stateAbrv: formData.get("state"),
					zipCode: formData.get("zipCode"),
					contactName: formData.get("contactName"),
					contactPhone: formData.get("contactPhone"),
					contactEmail: formData.get("contactEmail"),
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return response;
		} catch (error) {
			throw error;
		}
      } else {
		try {
			const response = client.put(
				`/locations/${shipToCode}`,
				{
					companyName: formData.get("companyName"),
					address: formData.get("address"),
					address2: formData.get("address2"),
					city: formData.get("city"),
					countryAbrv: formData.get("country"),
					stateAbrv: formData.get("state"),
					zipCode: formData.get("zipCode"),
					contactName: formData.get("contactName"),
					contactPhone: formData.get("contactPhone"),
					contactEmail: formData.get("contactEmail"),
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return response;
		} catch (error) {
			throw error;
		}
      }

}


// Invitation
export const confirmInvitationLink = (invitationCode, email) => {
	try {
		const response = client.post(
			'/get-invitation',
			{
				invitationCode: invitationCode,
				email: email,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
}

export const acceptInvitationRegister = (body) => {
	try {
		const response = client.post(
			'/accept-invitation',
			body
		);
		return response;
	} catch (error) {
		throw error;
	}
}

export const acceptInvitationExisting = (email, invitationCode) => {
	try {
		const response = client.patch(
			'/accept-invitation',
			{
				email: email,
				invitationCode: invitationCode,
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}

// STRIPE
export const checkCreditCard = (token) =>{
	try {
		const response = client.get(
			'/credit-card/check',
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}

export const addCreditCard = (token, stripeToken, defaultCard) => {
	try {
		const response = client.post(
			'/credit-card/add',
			{
				stripeToken: stripeToken,
				defaultCard: defaultCard
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}

export const getCreditCardList = (controller, token) => {
	try {
		const response = client.get(
			'/credit-card/list',
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
				signal: controller.signal
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}

export const getCreditCard = (controller, token, card_id) => {
	try {
		const response = client.get(
			`/credit-card/retrieve/${card_id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
				signal: controller.signal
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}

export const putCreditCard = (controller, token, data) => {
	try {
		const response = client.put(
			`/credit-card/${data.ID}`,
			{
				set_default: data.default,
				name: data.name,
				exp_month: data.exp_month,
				exp_year: data.exp_year,
				address_line1: data.address,
				address_line2: data.address2,
				address_city: data.city,
				address_zip: data.zip,
				address_country: data.country,
				address_state: data.state
		},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
				signal: controller.signal
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}

// 
export const deleteCreditCard = (token, cardId) => {
	try {
		const response = client.delete(
			`/credit-card/${cardId}`,
			{
				headers: {
					"Authorization": `Bearer ${token}`,
				},
			}
		)
		return response;
	}
	catch (error) {
		throw error; // Rethrow the error so it can be caught by the caller
	}
}

export const updateStripeCustomer = (token) => {
	try {
		const response = client.put(
			'/credit-card/customer/update',
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
}


export const postContactUsForm = (name, email, companyName, phone, message) => {
	try {
		const response = client.post(
			'/contact-us',
			{
				name: name,
				email: email,
				companyName: companyName,
				phone: phone,
				message: message,
			}
		)
		return (response);
	} catch (error) {
		return (error);
	}
}