import React, { lazy, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Layout from './components/Layout';
import RequireAuth from './components/RequireAuth';
import useAuth from "./hooks/useAuth";
import ForgotPage from './pages/Forgot-password';
import Dashboard from './pages/dashboard/Index';
// import MyAccount from './pages/editMyAccount/Index';
// import LocationsPage from './pages/locations/Index';
// import Reporting from './pages/reporting/Index';
// import Resources from './pages/resources/index';
// import UserListPage from './pages/userManagement/Index';

const Home = lazy(() => import('./pages/Home'));
const Register = lazy(() => import('./pages/register/Index'));
const ContactUs = lazy(() => import('./pages/contact/Index'));
const LogoutPage = lazy(() => import('./pages/Logout'));
const NotFound = lazy(() => import('./pages/404'))
const ConfirmEmail = lazy(() => import('./pages/my-account/confirm-email'));
const SetupNewPassword = lazy(() => import('./pages/my-account/setup-new-password'));
const EligiblePage = lazy(() => import('./pages/eligible'));
const MyAccount = lazy(() => import('./pages/editMyAccount/Index'));
const UserListPage = lazy(() => import('./pages/userManagement/Index'));

const OrderSupplies = lazy(() => import('./pages/recycling/order-supplies/index'));
const PalletPickup = lazy(() => import('./pages/recycling/pallet-pickup/index'));
const Reporting = lazy(() => import('./pages/reporting/Index'));
const Resources = lazy(() => import('./pages/resources/index'));
const LocationsPage = lazy(() => import('./pages/locations/Index'));

const AcceptInvitation = lazy(() => import('./pages/my-account/invitation/index'));

const PrivacyPolicy = lazy(() => import('./pages/privacy-policy/index'));
const TermsAndConditions = lazy(() => import('./pages/terms-and-conditions/index'));

const CreditCardList = lazy(() => import('./pages/my-account/credit-cards/index'));
const CreditCardUpdate = lazy(() => import('./pages/my-account/credit-cards/updateCard'));
const CreditCardAdd = lazy(() => import('./pages/my-account/credit-cards/addCard'));

function App() {
	const navigate = useNavigate();
	const location = useLocation();
	const { auth } = useAuth();

	useEffect(() => {
		// Redirect to /dashboard if user is authenticated
		if (auth && location.pathname === '/') {
			navigate('/dashboard');
		}
	}, [auth, location, navigate]);


	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				{/* public route */}
				<Route path='*' element={<NotFound />} />

				<Route path="/" element={<Home />} />
				<Route path="/register" element={<Register />} />
				<Route path="/forgot-password" element={<ForgotPage />} />
				<Route path="/contact-us" element={<ContactUs />} />
				<Route path="/logout" element={<LogoutPage />} />

				<Route path="/eligible" element={<EligiblePage />} />

				<Route path="/my-account/confirm-email/:hash" element={<ConfirmEmail />} />
				<Route path="/my-account/setup-new-password/:hash" element={<SetupNewPassword />} />
				<Route path="/my-account/invitation" element={<AcceptInvitation />} />
				<Route path="/privacy-policy" element={<PrivacyPolicy />} />
				<Route path="/terms-and-conditions" element={<TermsAndConditions />} />
				
				{/* protected routes */}
				<Route element={<RequireAuth />}>
					<Route path="/dashboard" element={<Dashboard />} />
					<Route path="/contact-us" element={<ContactUs />} />
					<Route path="/my-account" element={<MyAccount />} />
					<Route path="/my-account/cards" element={<CreditCardList />} />
					<Route path="/my-account/card/:id" element={<CreditCardUpdate />} />
					<Route path="/my-account/card/add" element={<CreditCardAdd />} />
					<Route path="/user/user-management" element={<UserListPage />} />
					<Route path="/recycling/resources" element={<Resources />} />
					<Route path="/recycling/reporting" element={<Reporting />} />
					<Route path="/recycling/order-supplies" element={<OrderSupplies />} />
					<Route path="/recycling/pallet-pickup" element={<PalletPickup />} />
					<Route path="/recycling/locations" element={<LocationsPage />} />

				</Route>
			</Route>
		</Routes>

	);
}

export default App;
