import { useEffect, useState } from "react";

const useCountries = () => {

    const [countries, setCountries] = useState([
        {
            "abrv": "US",
            "name": "USA",
            "states": [
                {
                    "abrv": "AL",
                    "name": "Alabama"
                },
                {
                    "abrv": "AZ",
                    "name": "Arizona"
                },
                {
                    "abrv": "AR",
                    "name": "Arkansas"
                },
                {
                    "abrv": "CA",
                    "name": "California"
                },
                {
                    "abrv": "CO",
                    "name": "Colorado"
                },
                {
                    "abrv": "CT",
                    "name": "Connecticut"
                },
                {
                    "abrv": "DE",
                    "name": "Delaware"
                },
                {
                    "abrv": "DC",
                    "name": "District of Columbia"
                },
                {
                    "abrv": "FL",
                    "name": "Florida"
                },
                {
                    "abrv": "GA",
                    "name": "Georgia"
                },
                {
                    "abrv": "ID",
                    "name": "Idaho"
                },
                {
                    "abrv": "IL",
                    "name": "Illinois"
                },
                {
                    "abrv": "IN",
                    "name": "Indiana"
                },
                {
                    "abrv": "IA",
                    "name": "Iowa"
                },
                {
                    "abrv": "KS",
                    "name": "Kansas"
                },
                {
                    "abrv": "KY",
                    "name": "Kentucky"
                },
                {
                    "abrv": "LA",
                    "name": "Louisiana"
                },
                {
                    "abrv": "ME",
                    "name": "Maine"
                },
                {
                    "abrv": "MD",
                    "name": "Maryland"
                },
                {
                    "abrv": "MA",
                    "name": "Massachusetts"
                },
                {
                    "abrv": "MI",
                    "name": "Michigan"
                },
                {
                    "abrv": "MN",
                    "name": "Minnesota"
                },
                {
                    "abrv": "MS",
                    "name": "Mississippi"
                },
                {
                    "abrv": "MO",
                    "name": "Missouri"
                },
                {
                    "abrv": "MT",
                    "name": "Montana"
                },
                {
                    "abrv": "NE",
                    "name": "Nebraska"
                },
                {
                    "abrv": "NV",
                    "name": "Nevada"
                },
                {
                    "abrv": "NH",
                    "name": "New Hampshire"
                },
                {
                    "abrv": "NJ",
                    "name": "New Jersey"
                },
                {
                    "abrv": "NM",
                    "name": "New Mexico"
                },
                {
                    "abrv": "NY",
                    "name": "New York"
                },
                {
                    "abrv": "NC",
                    "name": "North Carolina"
                },
                {
                    "abrv": "ND",
                    "name": "North Dakota"
                },
                {
                    "abrv": "OH",
                    "name": "Ohio"
                },
                {
                    "abrv": "OK",
                    "name": "Oklahoma"
                },
                {
                    "abrv": "OR",
                    "name": "Oregon"
                },
                {
                    "abrv": "PA",
                    "name": "Pennsylvania"
                },
                {
                    "abrv": "RI",
                    "name": "Rhode Island"
                },
                {
                    "abrv": "SC",
                    "name": "South Carolina"
                },
                {
                    "abrv": "SD",
                    "name": "South Dakota"
                },
                {
                    "abrv": "TN",
                    "name": "Tennessee"
                },
                {
                    "abrv": "TX",
                    "name": "Texas"
                },
                {
                    "abrv": "UT",
                    "name": "Utah"
                },
                {
                    "abrv": "VT",
                    "name": "Vermont"
                },
                {
                    "abrv": "VA",
                    "name": "Virginia"
                },
                {
                    "abrv": "WA",
                    "name": "Washington"
                },
                {
                    "abrv": "WV",
                    "name": "West Virginia"
                },
                {
                    "abrv": "WI",
                    "name": "Wisconsin"
                },
                {
                    "abrv": "WY",
                    "name": "Wyoming"
                }
            ]
        },
        {
            "abrv": "CA",
            "name": "Canada",
            "states": [
                {
                    "abrv": "AB",
                    "name": "Alberta"
                },
                {
                    "abrv": "BC",
                    "name": "British Columbia"
                },
                {
                    "abrv": "MB",
                    "name": "Manitoba"
                },
                {
                    "abrv": "NB",
                    "name": "New Brunswick"
                },
                {
                    "abrv": "NL",
                    "name": "Newfoundland-Labrado"
                },
                {
                    "abrv": "NT",
                    "name": "Northwest Territories"
                },
                {
                    "abrv": "NS",
                    "name": "Nova Scotia"
                },
                {
                    "abrv": "NU",
                    "name": "Nunavut"
                },
                {
                    "abrv": "ON",
                    "name": "Ontario"
                },
                {
                    "abrv": "PE",
                    "name": "Prince Edward Island"
                },
                {
                    "abrv": "QC",
                    "name": "Quebec"
                },
                {
                    "abrv": "SK",
                    "name": "Saskatchewan"
                },
                {
                    "abrv": "YT",
                    "name": "Yukon"
                }
            ]
        }
    ])
    return countries;
}

export default useCountries;