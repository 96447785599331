import { Link } from 'react-router-dom';
import styles from "../../styles/components/login.module.scss"
import ForgotForm from "./ForgotForm";
import useAuth from "../../hooks/useAuth";

export default function Forgot() {
  const { auth } = useAuth();
  // console.log('auth: ', auth);

  if (auth?.user) {
    return (
      <>
        <h1 className="h3">Welcome, {auth?.user.firstName}</h1>
        <div className="text-uppercase fw-lighter pb-3">
          We can help you navigate the future
        </div>
        <div className="clGreenLine"></div>

        <h4 className="py-3">role: {auth?.user.role}</h4>

        <Link to="/recycling" className="btn btn-primary text-light">
          Recycling Center
        </Link>
      </>
    );
  }

  if (!auth?.user) {
    return (
      <>
        <div className={`pb-5 ${styles.clModal}`}>
          <h1 className="h3 text-green">Reset your Password</h1>
          <ForgotForm />
        </div>
      </>
    );
  }

}
