function Terms() {
	return (
		<div className="terms_content">
			<h1>Clover Environmental Solutions<br></br>RECYCLING PROGRAM TERMS</h1>
			<h4>CLOVERIMAGING.COM</h4>
			<p><strong>Last Modified:</strong> November 15, 2023</p>

			<p>Clover Environmental Solutions (“CES”) provides a printer cartridge recycling program (the “Program”) that allows you to return the printer cartridges identified on the qualifying list located at <a href={process.env.REACT_APP_PUBLIC_BASEURL + "/eligible"}>recycleresponsible.com/eligible</a> (“Qualifying Items”) for remanufacturing or recycling. Any item that is not a Qualifying Item is referred to in these terms as a “Non-Qualifying Item”.</p>

			<p><strong>YOU MAY BE CHARGED A RECYCLING FEE FOR RETURNING NON-QUALIFYING ITEMS AND/OR FOR OTHERWISE FAILING TO FOLLOW THE PROGRAM TERMS SET FORTH BELOW.</strong></p>

			<p>The list of Qualifying Items and Recycling Fee schedule found below may be modified by CES, in CES’s sole discretion. <u>You are strongly encouraged to review the qualifying list before sending in each shipment.</u> Any updates to the list of Qualifying Items or Recycling Fee schedule will become effective on January 1, April 1, July 1, or October 1 of each year.</p>

			<p>Please carefully review the following Program terms (the “Terms”).  You must accept the Terms in order to register for and participate in the Program.</p>

			<p>By establishing an account, you hereby confirm that you are an authorized representative of the entity you are enrolling in the Program (the “Participant”) and are authorized to enroll the Participant, authorize additional users (if any), and agree to the Program Terms on Participant’s behalf.  Participation in the Program is subject to the following Terms, which may be updated from time to time at the sole discretion of Clover Environmental Solutions (“CES”).  By registering for the Program, you acknowledge that you have read these Terms and agree, on behalf of Participant, to be bound and abide by these Terms, our Privacy Policy and our Terms and Conditions, found at <a href={process.env.REACT_APP_PUBLIC_BASEURL}>https://recycleresponsible.com</a> and incorporated herein by reference.  Should you object to any term or condition of these Terms, any guideline, or any subsequent modification thereto, your only recourse is to immediately discontinue participating in the Program.</p>

			<p>Participant must designate a coordinator (the "Coordinator") to manage Participant’s use of the Program. Participant's account and credits may be accessed only by means of the username and password of the Coordinator designated for the Participant's account. CES shall be entitled to rely absolutely and conclusively on all transactions, including authorization of additional users, if any, undertaken or authorized by means of the username and password of the Participant's Coordinator and all such transactions shall be binding on the Participant.</p>

			<p><u>Payment Method</u></p>

			<p>To participate in the Program, you must have internet access, establish a Participant account at recycleresponsible.com, and provide CES’s payment processing partner with one or more Payment Methods. “Payment Method” means a current, valid, accepted method of payment, such as a credit card. All Payment Method information will at all times remain with STRIPE and will be subject to STRIPE’s terms and conditions as set forth at stripe.com. CES does not store or have access to your Payment Method information.</p>

			<p>By participating in the Program and providing your Payment Method, you authorize CES to initiate charges to your Payment Method for any Recycling Fees that you or any authorized user incurs as a result of your participation in the Program. The schedule of Recycling Fees is set forth below. Any charges to your Payment Method will be made on or around the first of each month in accordance with the Payment Terms set forth below. You can update your Payment Method by going to your “Account” page. We may also update your Payment Method using information provided by the payment service providers. Following any update, you authorize us to continue to charge the applicable Payment Method.</p>

			<p><u>Additional Users</u></p>

			<p>Participants who are office product dealers may be eligible to authorize their customers to participate in the program under the dealer’s account by authorizing such customers as additional users under the Participant’s account. Additional users will be given their own username and set their own password, but will not be required to provide a Payment Method. As such, all Program Credits earned or Recycling Fees incurred by an additional authorized user will be credited or charged to the Participant account with which the additional user is associated. Participants will have the option to limit each additional user’s access to certain Program features, but ultimately any Participant that authorizes additional users will be responsible for all of such additional user’s activity under the Program, including payment of any Recycling Fees incurred, and Participant hereby affirmatively agrees to accept financial responsibility for all such additional user activity. <strong>Accounts that authorize additional users are strongly encouraged to ensure their additional users are familiar with these Terms, the difference between Qualifying and Non-Qualifying items, and the credits/charges associated with each.</strong></p>

			<p><u>Program Credits</u></p>

			<p>Participants may be eligible to earn Program Credit for returning Qualifying Items. Credit in the Program will be awarded by CES to eligible Participants for return shipments that include Qualifying Items and credited to Participant’s account based upon the values identified on the qualifying list located at <a href={process.env.REACT_APP_PUBLIC_BASEURL + "/eligible"}>recycleresponsible.com/eligible</a>.  CES, in its sole discretion, will determine, which types of products are Qualifying Items and the monetary credit value assigned to each Qualifying Item.</p>

			<p>CES will not issue credits for Non-Qualifying Items, or for Qualifying Items that are damaged at the time of processing by CES. Whether a Qualifying Item is damaged shall be determined by CES in its sole discretion.</p>

			<p>CES remanufactured cartridges that are not specifically identified as a Qualifying Item will not qualify for program Credit, but will also not incur any Recycling Fees.</p>
			<a id="rec_fees">{""}</a>
			<p><u>Recycling Fees</u></p>

			<p>All Non-Qualifying items sent to CES under the Program will incur a Recycling Fee to cover the cost of responsibly recycling or otherwise disposing of those items. Recycling Fees will also apply for failure to follow these Terms and/or the shipping requirements set forth in this section. <strong>By participating in the Program you agree that you will be financially responsible for all Recycling Fees associated with your account and authorize CES to charge all such Recycling Fees that are not offest by Program Credits to your Payment Method.</strong> The current schedule of Recycling Fees is as follows:</p>

			<ul>
				<li>$0.50 per non-qualifying inkjet cartridge or ribbon</li>
				<li>$3.00 per non-qualifying toner cartridge</li>
				<li>$3.00 per non-qualifying toner tube, toner bottle or toner tank</li>
				<li>$3.00 per non-qualifying printer components</li>
				<li>$3.00 per non-qualifying cellphone or small electronic</li>
				<li>$12.00 per box for each box that contains less than at least 20 pounds of Qualifying Items or 20 units of Qualifying Items, but only if you are using a CES prepaid shipping label.</li>
				<li>$6.00 for CES-provided recycling boxes that remain unreturned after a period of 1 year.</li>
				<li>All freight charges if CES is picking up pallets of items and the shipments contains less than 5 pallets or an average of less than 50 Qualifying Items per pallet.</li>
				<li>All charges, fees, costs, or expenses incurred by CES resulting from Participant’s failure to abide by these Terms or Participant sending in items not otherwise mentioned in these Terms.</li>
			</ul>

			<p><u>Payment Terms and Timing</u></p>

			<p>CES will maintain a Participant account that tracks all Program Credit and Recycling Fees over time based on Participant’s (and Participant’s authorized additional users, if any) actual returns under the Program.</p>

			<p>CES will automatically issue payment when the positive value of Participant’s account reaches at least twenty-five dollars ($25.00).  CES reserves the right, in its sole discretion, to issue payment for positive amounts less than twenty-five dollars ($25.00), for example to clean up dormant accounts. All Payments under the Program will be made by check or ACH to the address or bank information associated with Participant’s account. It is Participant’s responsibility to ensure that all address and banking information is kept up to date.</p>

			<p>Once payment is made, Participant’s account balance will be reset to zero.  Lost or stolen checks that have been cashed will not be reissued.  Participant is responsible for taxes, charges or other liabilities related to or resulting from participation in the Program.</p>

			<p>CES will charge the Payment Method on file for all negative balances in excess of twenty-five dollars ($25.00) on or around the first of each month. CES reserves the right, in its sole discretion, to charge the Payment Method for negative amounts less than twenty-five dollars ($25.00), for example to clean up dormant accounts. Once the charge has cleared, Participant’s account balance will be reset to zero.</p>

			<p><u>General Terms</u></p>

			<p>Either party, without notice, may terminate this Agreement at any time for any reason, provided however that any payments due to or from the Participant shall remain due and payable until settled in full. Participant will remain liable for any returns received using shipping labels or boxes that were issued under Participant’s account.</p>

			<p>Participant agrees to indemnify and hold CES harmless from any claim or demand, including reasonable attorney fees, made by any third party arising out of, or related to, Participant’s or any authorized additional user’s violation of these Terms or violation of any law, regulation or third-party right.</p>

			<p>These Terms constitute the entire agreement between Participant and CES and govern Participant’s participation in the Program and superseding any prior agreements between Participant and CES.</p>

			<p>These Terms and the relationship between Participant and CES shall be governed by the laws of the State of Illinois without regard to its conflict of law provisions. Any failure of CES to exercise or enforce any right or provision of the Terms shall not constitute a waiver of such right or provision.  If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of these Terms remain in full force and effect.  Participant agrees that, regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to participation in the Program or these Terms must be filed within one (1) year after such claim or cause of action arose or be forever barred.</p>

			<p>The Program is owned and operated by Clover Environmental Solutions, LLC, 4200 Columbus St., Ottawa, IL 61350.</p>
		</div>
	);
}

export default Terms;
