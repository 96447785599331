import React from 'react';
import { Outlet } from 'react-router-dom';
import Navigation from "../components/Navigation";
import useAuth from "../hooks/useAuth";
import BadResponseModal from '../modals/badResponseModal';
import ForcePasswordUpdate from '../modals/forcePasswordUpdate';
import Footer from './Footer';
import Header from './Header';
import SessionExpiredModal from './modal/sessionExpiredModal';
import NonConfirmEmail from './nonConfirmEmail';
import Stripe from './stripe';
import CookieBanner from './cookieBanner';

export default function Layout() {
	const { auth } = useAuth();
	return (
		<>
		<div className="pageWrap">
			{auth ? <NonConfirmEmail /> : null }
			<Header />
			{auth ? <Navigation /> : null }

			<div className="pageInner">
				<div className="clMainBg">
					<div className="container">
						<Outlet />
					</div>
				</div>
			</div>
			<Footer />
			<SessionExpiredModal />
			<ForcePasswordUpdate />
			<BadResponseModal />
			
			{auth ? <Stripe /> : null }
		</div>
		<CookieBanner />
		</>
	)
}
